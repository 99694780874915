import Base = require("Everlaw/Base");
import BaseComboBox = require("Everlaw/UI/BaseComboBox");
import Dom = require("Everlaw/Dom");
import DomText = require("Everlaw/Dom/Text");
import Util = require("Everlaw/Util");
import dijit_Tooltip = require("dijit/Tooltip");
import { Is, Num, Str } from "core";
import Tooltip = require("Everlaw/UI/Tooltip");
import { AutocompleteTerm } from "Everlaw/Type/AutocompleteTerm";

/** A single-select dropdown that also allows for adding new elements. */
class ComboBox extends BaseComboBox<Base.Object> {}

module ComboBox {
    export interface CompletionMatch {
        term: AutocompleteTerm;
        hits: number;
        display?: string;
    }

    export interface Completions {
        matches: CompletionMatch[];
        total: number;
        // relevant only to FilePathTerm
        docCount?: number;
    }

    export class AutocompleteData extends Base.Primitive<string> {
        /**
         * Cannot ever return 0 or there will be an error when making menu selections.
         */
        private customCompare?: (ad1: AutocompleteData, ad2: AutocompleteData) => number;

        constructor(
            public hits: number,
            public term: AutocompleteTerm,
            display?: string,
            customCompare?: (ad1: AutocompleteData, ad2: AutocompleteData) => number,
        ) {
            super(term.toString(), display || term.toString());
            this.customCompare = customCompare;
        }

        /**
         * The only time data should be negative is with the "no results" option (and other similar options)
         * which should be shown at the top of the list.  Because the elements are assumed sorted in
         * _getElement's binarySearch, we don't want to rearrange the elements manually, so instead we
         * define the sort to place negative values before positive values, though positive values are
         * sorted descending.
         */
        override compare(other: AutocompleteData): number {
            if (this.customCompare) {
                return this.customCompare(this, other);
            }
            const cmp =
                this.hits < 0 || other.hits < 0 ? this.hits - other.hits : other.hits - this.hits;
            // fallback to term comparison if necessary
            return cmp !== 0 ? cmp : super.compare(other);
        }
    }

    export interface AutocompleteParams extends BaseComboBox.Params<AutocompleteData> {
        delay?: number;
        getCompletions: (val: string) => Promise<Completions>;
        alwaysShowNewOption?: boolean;
        stayFilteredOnSelect?: boolean;
        // if isAllowMultiSelect is true together with stayFilteredOnSelect, then multiple result rows
        // can have the class BaseSelect::SELECTED_CLASS
        isAllowMultiSelect?: boolean;
        externalFilter?: (term: AutocompleteTerm) => boolean;
        termFromJson?: (obj: any) => AutocompleteTerm;
        hideStats?: boolean;
        noun?: string;
        dontShowInputAsFirstRow?: boolean;
        firstRowNode?: HTMLElement;
        resultRowAdditionalClass?: string;
        // whether results are shown in a popup menu or in a persistently appearing
        // element below the text input box
        popup?: boolean | string;
        /**
         * Used to sort displayed autocomplete results.
         * Cannot ever return 0 or there will be an error when making menu selections.
         */
        customAutocompleteDataCompareOperation?: (
            ad1: AutocompleteData,
            ad2: AutocompleteData,
        ) => number;
        showTooltip?: boolean;
    }

    /** Text input with suggestions fetched from the server. */
    export class Autocomplete extends BaseComboBox<AutocompleteData> {
        /**
         * Returns Completions on success, or a string message on error.
         */
        getCompletions(val: string): Promise<string | Completions> | null {
            return null;
        }

        /**
         * Overridden via mixin. See {@link SearchTerms#createMetadataTextWidget}.
         */
        termFromJson(obj: any): AutocompleteTerm {
            return obj;
        }

        delay = 200; // time (ms) to wait before making a new request to the server
        _curRequest: Record<string, never> | null;

        /**
         * Whether not to hide the "new" value from the options if it exists exactly in the completions.
         * This is important in address list search as we should still allow user to search for text
         * even if it's a name (or email) existing in the completions, as the search behavior is different.
         */
        alwaysShowNewOption: boolean;
        dontShowInputAsFirstRow: boolean;

        /**
         * When a widget (e.g. {@link AddressSearchWidget}) keeps track of multiple selected values,
         * this allows it to filter out values already chosen.
         */
        externalFilter: (term: AutocompleteTerm) => boolean;

        /**
         * Whether we show the approximate document count (stats) in the rows element
         */
        hideStats: boolean;

        /**
         * The singular form of what to display after the number. Defaults to "doc", which usually
         * displays "docs"
         */
        private noun: string;

        /**
         * CSS class where the stats (e.g. doc counts) of each autocompleted term are displayed if
         * hideStats = false
         */
        static STAT_DIV_CLASS = "ac-term__stats";

        /**
         * The first row always shown in the results menu.
         */
        private firstRowNode: HTMLElement;
        /**
         * Additional class added to each result row to allow for more custom styling
         */
        private resultRowAdditionalClass: string;
        /**
         *
         * If you want to show a tooltip for each row element
         */
        private showTooltip: boolean;
        /**
         * Used to sort displayed autocomplete results.
         * Cannot ever return 0 or there will be an error when making menu selections.
         */
        private customAutocompleteDataCompareOperation?: (
            ad1: AutocompleteData,
            ad2: AutocompleteData,
        ) => number;
        constructor(params: AutocompleteParams) {
            super(
                Object.assign(params, {
                    popup: Is.defined(params.popup) ? params.popup : "after",
                    headers: false,
                    dontShowSelectIcon: true,
                }),
            );
            this.noun = this.noun || "doc";
            // Send autocomplete.rest request to the server
            if (!this.manualFilter) {
                this._filter("");
            }
            if (this.firstRowNode) {
                Dom.place(this.firstRowNode, this.menu, "first");
            }
            this.customAutocompleteDataCompareOperation =
                params.customAutocompleteDataCompareOperation;
            this.showTooltip = !!params.showTooltip;
        }

        protected _clear() {
            this.unhover();
            this.selectedIdx = null;
            if (this.visible[0] && this.isNewNode(this.visible[0].node)) {
                this.visible.shift();
            }
            for (let i = this.visible.length; --i >= 0; ) {
                const element = this.visible[i].element;
                element && this.removeElement(element, i);
            }
            this.visible = [];
            this._curRequest = null;
            this.toggleHeader(false);
        }
        protected override _onBlur() {
            dijit_Tooltip.hide(this.node);
            super.onBlur();
        }
        override _filter(val: string) {
            dijit_Tooltip.hide(this.node);
            const curRequest = (this._curRequest = {});
            return new Promise<Completions>((resolve, reject) => {
                setTimeout(() => {
                    if (this._curRequest !== curRequest) {
                        return reject();
                    }
                    this.getCompletions(val)?.then((result) => {
                        if (this._curRequest !== curRequest) {
                            return reject();
                        }
                        this._clear();
                        if (typeof result === "string") {
                            dijit_Tooltip.show(DomText.escapeHtml(result), this.node, ["below"]);
                            return reject();
                        }
                        let elements = result.matches.map(
                            (match) =>
                                new AutocompleteData(
                                    match.hits,
                                    this.termFromJson(match.term),
                                    match.display,
                                    this.customAutocompleteDataCompareOperation,
                                ),
                        );
                        if (this.externalFilter) {
                            elements = elements.filter((d) => this.externalFilter(d.term));
                        }

                        // Sorting here even though the server returns the data sorted, since I'm not sure
                        // the CASE_INSENSITIVE_ORDER from the server is exactly the same as the locale
                        // order we use in the client (for the term, in the case the data is the same,
                        // see compare above). The comparator is needed by the binary search on the
                        // selected options in getElement.
                        // It appears selecting breaks if you do not sort with `this.comparator`,
                        // so for custom ordering, we must provide params.customAutocompleteDataCompareOperation
                        // to do sorting on the front-end,
                        // rather than sorting on the back-end and not sorting on the front-end at all.
                        elements.sort(this.comparator);

                        this.computeLeftTruncateOffset([elements]);
                        this.visible = elements.map((e, i) => {
                            const element = this.addElement(e, i);
                            const byClass = this.forClass(this.getClassName(e));
                            const row = element.row();
                            Dom.place(row, byClass.body, i);
                            const disps = this.getDisplayValues(e, this);
                            this.highlightMatches(row, disps, val.toLowerCase().trim());
                            return { element: e, node: Dom.node(row) };
                        });
                        // Terms with negative hits are the "No value", "Any value", etc. special
                        // values. They don't count.(0 hits shouldn't exist but being defensive)
                        const matchCount = result.matches.filter((m) => m.hits >= 0).length;
                        const total = result.total;
                        // total is 0 if we do not know the exact total (some alias field cases),
                        // unless of course we have no matches, in which case it's definitely 0
                        const unknownTotal = matchCount > 0 && total === 0;
                        const exceeded = total > matchCount || unknownTotal;
                        if (exceeded) {
                            this.setHeader(
                                "Only showing the top "
                                    + matchCount
                                    + (unknownTotal ? "" : ` of ${total}`)
                                    + " results.",
                            );
                        }
                        this.toggleHeader(exceeded);
                        if (this.dontShowInputAsFirstRow) {
                            if (this.visible.length === 0) {
                                Dom.show(this.noResults);
                            } else {
                                Dom.hide(this.noResults);
                            }
                        } else {
                            this.updateNewNode(val, this.defaultNewClassStr(), 0);
                        }
                        if (this.firstRowNode) {
                            this.toggleFirstRowNode(true);
                        }

                        this.updateIndexes();
                        resolve(result);
                    });
                }, this.delay);
            });
        }

        setFirstRowNodeText(text: string): void {
            Dom.setContent(this.firstRowNode, text);
        }

        toggleFirstRowNode(isShow?: boolean): void {
            Dom.show(this.firstRowNode, isShow);
        }

        protected override onTextBoxFocus(): void {
            super.onTextBoxFocus();
            if (this.dontShowInputAsFirstRow) {
                // onTextBoxFocus() calls drawSelected() which sets the first row to being hovered.
                // We do not want the hovered style for the first row so we call unhover()
                this.unhover();
            }
        }

        fieldChanged() {
            this._clear();
            this.tb.clear();
            this.onUpdate();
        }
        forceUpdate() {
            this.onUpdate();
        }
        protected override prepRowElement(data: AutocompleteData | string) {
            // Avoid the complex markup for simple string (user entered text case), plus it allows
            // for simpler CSS to do the extra styling for user entered new node.
            if (typeof data === "string") {
                return super.prepRowElement(data);
            }

            const term = data.term;
            let rowClass = "ac-row table-row action description";
            if (this.resultRowAdditionalClass) {
                rowClass += " " + this.resultRowAdditionalClass;
            }
            if (this.dontShowSelectIcon) {
                rowClass += " dont-show-select-icon";
            }
            const elem = Dom.div(
                { class: rowClass },
                this.termIcon(term),
                Dom.div(
                    { class: "ac-row__main" },
                    // the main row with the label and stats
                    Dom.div(
                        { class: "ac-term" },
                        Dom.div(
                            { class: "ac-term__label" },
                            Dom.span({ class: "label-node" }, this.shortDisplay(data)),
                        ),
                        Dom.div({ class: Autocomplete.STAT_DIV_CLASS }, Dom.span(this.stats(data))),
                    ),
                    // the associated sub-rows (emails currently)
                    ...this.associatedRows(term).map((row) =>
                        Dom.div({ class: "ac-row__sub" }, row),
                    ),
                ),
            );
            const destroyables: Util.Destroyable[] = [];
            if (this.showTooltip) {
                const tooltip = new Tooltip(elem, data.display());
                destroyables.push(tooltip);
            }

            return { node: elem, onDestroy: destroyables };
        }

        // get the element with a class of Autocomplete.STAT_DIV_CLASS or null if there is no matching element
        private getRowStatDiv(rowNode: Element): Element {
            return rowNode.getElementsByClassName(Autocomplete.STAT_DIV_CLASS)[0];
        }

        createRowStat(rowNode: Element, stat: string): void {
            this.deleteRowStat(rowNode);
            const rowStatDiv = this.getRowStatDiv(rowNode);
            if (rowStatDiv) {
                Dom.place(Dom.span(stat), rowStatDiv);
            }
        }

        deleteRowStat(rowNode: Element): void {
            const rowStatDiv = this.getRowStatDiv(rowNode);
            if (rowStatDiv && rowStatDiv.childNodes) {
                for (const stat of Array.from(rowStatDiv.childNodes)) {
                    Dom.destroy(stat);
                }
            }
        }

        private termIcon(term: AutocompleteTerm) {
            if (typeof term === "string") {
                return null;
            }
            const icon = term.icon();
            return icon && icon.node;
        }

        private stats(data: AutocompleteData): string {
            const count = data.hits;
            if (count < 0 || this.hideStats) {
                return "";
            }

            const countText = Num.displayNumber(count);
            const nounMaybePluralized = Str.pluralForm(this.noun, count);

            const term: AutocompleteTerm = data.term;
            const estimate = typeof term !== "string" && term.hitsIsLowerBound() ? "≥ ~" : "~";

            return `${estimate}${countText} ${nounMaybePluralized}`;
        }

        private associatedRows(term: AutocompleteTerm) {
            return typeof term === "string" ? [] : term.associatedRows();
        }

        /**
         * Allow any text if flag is true. Otherwise call superclass method, which does not allow
         * new value that is already exactly in the completions.
         */
        override canAdd(name: string, clazz: string) {
            return this.alwaysShowNewOption || super.canAdd(name, clazz);
        }
        override destroy() {
            this._curRequest = null;
            super.destroy();
        }
        override shortDisplay(e: Base.Primitive<string>) {
            const shortDisplay = super.shortDisplay(e);
            if (this.leftTruncateOffset) {
                return "\u2026" + shortDisplay.substr(this.leftTruncateOffset);
            }
            return shortDisplay;
        }
        protected override computeLeftTruncateOffset(elems: Base.Primitive<string>[][]) {
            if (this.truncateOnLeftWidth > 0) {
                let commonPrefix: string | null = null;
                let maxWidth = 0;
                for (const byClass of elems) {
                    for (const elem of byClass) {
                        commonPrefix =
                            commonPrefix == null
                                ? elem.display()
                                : Str.commonStartString(elem.display(), commonPrefix);
                        maxWidth = Math.max(maxWidth, elem.display().length);
                    }
                }
                this.leftTruncateOffset = Math.min(
                    Math.max(0, commonPrefix ? commonPrefix.length - 1 : 0),
                    Math.max(0, maxWidth - this.truncateOnLeftWidth),
                );
            }
        }
    }
}

export = ComboBox;
